<template>
  <v-app>
      <v-progress-linear class="progress-bar" :active="loading" absolute top :indeterminate="true"></v-progress-linear>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute color="transparent">
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-6">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <v-spacer></v-spacer>
          <!-- Right Content -->
          <theme-switcher></theme-switcher>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6">
        <slot></slot>
      </div>
    </v-main>

    <v-footer app inset color="transparent" absolute height="56" class="px-0"> </v-footer>
  </v-app>
</template>

<script>
import { ref, computed, watch, getCurrentInstance } from 'vue'
import { mdiMagnify, mdiBellOutline, mdiGithub } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  watch: {

  },
  computed: {
    loading() {
      return this.$store.state.loading
    },
    shop: {
      get() {
        return this.$store.state.shop
      },
      set(v) {
        this.$store.dispatch('setStore', v)
      }
    },
    shops() {
      return this.$store.state.shops
    },
    shop_url() {
      return this.$store.state.me.data.shop_url
        .replace(/https:\/\//gi, '')
        .replace('www.', '')
        .replace(/\//gi, '')
    },
  },
  data() {
    return {
    }
  },
  mounted() {
    const ins = getCurrentInstance()?.proxy
    
    const shops = computed(() => this.$store.state.stats.shops)

    watch(
      shops,
      n => {
        ins.$forceUpdate()
      },
      { immediate: true },
    )

    this.axios.get('https://trayllo.com.br/wp-json/trayllo/v1/dashboard/shops').then(res => {
      this.$store.commit('setShops', res.data)
      if (!localStorage.shop) {
        this.$store.dispatch('setShop', res.data[0].id)
      }
    })
    this.axios.get('https://trayllo.com.br/wp-json/trayllo/v1/dashboard/sync/status').then(res => {
      this.syncing = res.data
      if (res.data === true) {
        this.sync_text = 'Já existe um sincronização em andamento'
      }
    })
  },
  methods: {
  },
  setup() {
    const isDrawerOpen = ref(null)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
        mdiGithub,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.lojas {
  margin-top: 15px;
}
.progress-bar {
  position: fixed;
  top: 0;
  z-index: 999999;
}
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}
.lojas {
  max-width: 300px;
}
.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
