<template>
  <v-select class="lojas" solo item-text="name" item-value="id" label="Selecionar a loja" v-model="shop" :items="shops">
  </v-select>
</template>

<script>
export default {
  computed: {
    shop_url() {
      return this.$store.state.me.data.shop_url
        .replace(/https:\/\//gi, '')
        .replace('www.', '')
        .replace(/\//gi, '')
    },
    shop: {
      get() {
        return this.$store.state.shop
      },
      set(v) {
        this.$store.dispatch('setStore', v)
        this.$store.dispatch('getUser')
      },
    },
    shops() {
      return this.$store.state.shops
    },
  },
}
</script>

<style lang="scss" scoped>
.lojas {
    padding-top: 15px;
    display: block;
}
</style>