import Vue from 'vue'
import Vuex from 'vuex'
import '../plugins/axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    stats: false,
    shops: null,
    shop: JSON.parse(localStorage.shop || '{}'),
    me: null,
    produtos: [],
    orders: [],
    carts: [],
    pages: 0,
    page: 0,
    alerts:[],
    syncing: false,
    loading: false
  },
  mutations: {
    setSyncing(state, payload) {
      state.syncing = payload
    },
    setLoading(state, payload) {
      state.loading = payload
    },
    setPages(state, payload) {
      state.pages = payload
    },
    addAlert(state, payload) {
      state.alerts.push(payload)
    },
    setPage(state, payload) {
      state.page = payload
    },
    setOrders(state, payload) {
      state.orders = payload
    },
    setProdutos(state, payload) {
      state.produtos = payload
    },
    setCarts(state, payload) {
      state.carts = payload
    },
    setStats(state, payload) {
      state.stats = payload
    },
    setMe(state, payload) {
      state.me = payload
    },
    setShop(state, payload) {
      state.shop = payload
      localStorage.shop = JSON.stringify(payload)
    },
    setShops(state, payload) {
      state.shops = payload
      localStorage.shops = JSON.stringify(payload)
    }
  },
  actions: {
    getUser(context) {
      Vue.axios.get('dashboard/me').then(res => {
        context.commit('setMe', res.data.data)
      })
    },
    getOrders(context) {
       Vue.axios.get('dashboard/orders?page=' + context.state.page).then(res => {
        context.commit('setOrders', res.data)
        context.commit('setPages', Math.round(res.headers['x-wp-total'] / 50))
      })
    },
    getProdutos(context) {
       Vue.axios.get('dashboard/produtos?page=' + context.state.page).then(res => {
        context.commit('setProdutos', res.data)
        context.commit('setPages', Math.round(res.headers['x-wp-total'] / 50))
      })
    },
    setStore(context, payload) {
      Vue.axios.post('dashboard/shops/' + payload).then(() => {
        let shop = context.state.shops.find(item => item.id == payload)
        context.commit('setShop', shop)
        context.dispatch('getUser')
        context.dispatch('getStats')
      })
    },
    getStats(context) {
      Vue.axios.get('dashboard/stats').then(res => context.commit('setStats', res.data))
    }
  },
  modules: {},
})
