import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from "vue-gtag";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/carrinhos',
    name: 'carrinhos',
    component: () => import('@/views/dashboard/Carts.vue'),
  },
  {
    path: '/tray',
    name: 'tray',
    component: () => import('@/views/dashboard/Tray.vue'),
  },
  {
    path: '/trello',
    name: 'trello',
    component: () => import('@/views/dashboard/Trello.vue'),
  },
  {
    path: '/carrinhos/:id',
    name: 'carrinho',
    component: () => import('@/views/dashboard/CartRecovery.vue'),
  },
  {
    path: '/produtos-vendidos',
    name: 'produtos-vendidos',
    component: () => import('@/views/dashboard/Produtos.vue'),
  },
  {
    path: '/pedidos',
    name: 'pedidos',
    component: () => import('@/views/dashboard/Orders.vue'),
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/conta',
    name: 'conta',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: process.env.NODE_ENV == 'production' ? 'history' : 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if(to.name !='login' && !localStorage.token) {
    return next('/login')
  }
  next()
})

Vue.use(VueGtag, {
  config: { id: "G-J49NHT55P0" }
}, router);

export default router
